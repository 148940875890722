.offline-box {
  display: flex;
  align-items: center;
  will-change: width, background-color;
  transition: width padding 0.2s ease-out;
}

.offline-box-animate {
  position: fixed;
  width: 200px;
  background-color: #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  padding: 0px 2px;
}

.message {
  margin-left: 10px;
  font-size: 13px;
  color: #000;
  animation: slideIn 750ms forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    margin-bottom: -15%;
  }
  100% {
    opacity: 1;
    margin-bottom: 1%;
  }
}

@media print {
  .offline-box {
    display: none;
  }
}
